.main-container {  display: grid;
  grid-template-columns: 1fr 1.5fr 1fr;
  grid-template-rows: 0.1fr 2.5fr 0.6fr; 
  gap: 0 15px;
  grid-auto-flow: row;
  grid-template-areas:
    ". main-presesentation-txt ."
    ". main-presentation-txt ."
    "main-bottom main-bottom main-bottom";

  border-width: 5px;
  border-color: rebeccapurple;
}

.main-presentation-txt { 
  grid-area: main-presentation-txt;
 }

.main-bottom {  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-template-rows: 1fr 1fr 1fr;
  gap: 0px 10px;
  grid-auto-flow: row;
  grid-template-areas:
    ". main-links ."
    ". . ."
    "main-controls . .";
  grid-area: main-bottom;
}

.main-links { 
  grid-area: main-links; 
  text-align: center;
}

.main-controls { grid-area: main-controls; }
