html,
body,
#root {
  height: 100%;
  margin: 0;
}

.app {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.title {
  flex: 0 0 20%;
  background-color: #f5f5f5;
  display: flex;
  justify-content: center;
  align-items: center;
}

.display {
  flex: 1;
  background-color: #ffffff;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 20px;
  max-height: 50%;
  overflow: auto;
}

.controls {
  flex: 0 0 30%;
  max-height: 30%;
  background-color: #eeeeee;
  display: flex;
  justify-content: space-around;
  align-items: center;
  padding: 10px;
  overflow: auto;
}

input[type="text"] {
  width: 50%;
  padding: 10px;
  font-size: 18px;
}

select {
  padding: 10px;
  font-size: 18px;
}

label {
  font-size: 18px;
}

.status-icon {
  position: fixed;
  top: 10px;
  right: 10px;
  font-size: 24px;
  padding: 5px;
}